html,
body {
	position: relative;
	height: 100%;
	overflow: auto;
}

body {
	margin: 0;
	font-family: 'Open Sans', Roboto, 'Helvetica Neue', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
#root {
	flex: 1 0 auto;
	width: 100vw;
	display: flex;
	flex-direction: column;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
